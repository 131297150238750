.input-counter:global(.ant-input-number) {
  z-index: 1;
  max-width: 86px;
  color: inherit;
  border-color: var(--main-gray-3);
  border-radius: 2px;
  transition:
    height 0s,
    padding 0s;
}
.input-counter:global.ant-input-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 22px;
}
.input-counter.centered input {
  text-align: center;
}
.input-counter::placeholder {
  color: var(--main-gray-7);
}
.input-counter :global .ant-input-number-group-addon .ant-btn:hover {
  color: var(--main-gray-7);
  background-color: var(--main-gray-3);
}
.input-counter :global .ant-input-number-group-addon .ant-btn.disabled {
  color: var(--main-gray-5);
  background-color: var(--main-gray-1);
  opacity: 0.7;
}
.input-counter:global(.ant-input-number .ant-input-number-handler-wrap) {
  opacity: 1;
}
.input-counter:global(.ant-input-number .ant-input-number-group-addon .ant-btn:focus) {
  color: var(--main-gray-9);
}
.input-counter :global .ant-input-number-handler-up-inner,
.input-counter :global .ant-input-number-handler-down-inner {
  right: 2px;
  width: 16px;
  height: 16px;
}
.input-counter :global .ant-input-number-handler-down-inner {
  top: 60%;
}
.input-counter .count-up-icon,
.input-counter .count-down-icon {
  width: 16px;
  height: 16px;
}
.input-counter.disabled {
  color: var(--main-gray-4);
}
.input-counter.disabled :global .ant-input-number-input {
  color: var(--main-gray-4);
}
.input-counter.disabled :global .ant-input-number-group-addon {
  background-color: var(--main-gray-4);
}
.input-counter-tooltip {
  max-width: 500px;
}
