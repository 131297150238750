.basket-choose-credit-card-form :global(.ant-row) {
  justify-content: center;
}
.basket-choose-credit-card-form :global(.ant-radio-group) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.basket-choose-credit-card-form :global(.ant-btn.ant-btn-block) {
  margin-top: 34px;
  animation: fade-in 0.6s;
  animation-fill-mode: forwards;
}
.basket-checkout-credit-cards__title {
  text-align: center;
}
.basket-payment__credit-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 56px;
  padding: 16px;
  background: var(--primary-blue-1);
}
.basket-payment__credit-card :global(.ant-radio-wrapper span.ant-radio + *) {
  padding-inline: 10px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
