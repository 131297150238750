.icon {
  display: inline-block;
  width: auto;
  height: auto;
  font-size: inherit;
  font-style: normal;
  line-height: 0;
  color: inherit;
  text-align: center;
  text-transform: none;
  vertical-align: middle;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon > * {
  line-height: 1;
}
.icon:global(.anticon) svg {
  display: inline-block;
  vertical-align: middle;
}
.icon:global(.anticon)::before {
  display: none;
}
span.icon.size-10:global(.anticon) {
  font-size: 10px;
}
span.icon.size-12:global(.anticon) {
  font-size: 12px;
}
span.icon.size-14:global(.anticon) {
  font-size: 14px;
}
span.icon.size-16:global(.anticon) {
  font-size: 16px;
}
span.icon.size-18:global(.anticon) {
  font-size: 18px;
}
span.icon.size-22:global(.anticon) {
  font-size: 22px;
}
.icon.size-24:global(.anticon) {
  font-size: 24px;
}
.icon.size-28:global(.anticon) {
  font-size: 28px;
}
.icon.size-32:global(.anticon) {
  font-size: 32px;
}
.icon.size-40:global(.anticon) {
  font-size: 40px;
}
.icon.size-48:global(.anticon) {
  font-size: 48px;
}
span.icon.size-inherit:global(.anticon) {
  font-size: inherit;
}
span.icon.size-full-width {
  width: 100%;
  height: 100%;
}
.icon.size-full-width svg {
  width: 100%;
  height: 100%;
}
.icon.rotate {
  animation: rotate 1s infinite ease-out;
}
.icon.mr-8 {
  margin-right: 4px;
}
.icon.ml-4 {
  margin-left: 4px;
}
.icon.mb-8 {
  margin-bottom: 8px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
