.header {
  position: relative;
  z-index: 100;
}
.header:global(.ant-layout-header) {
  width: 100%;
  height: auto;
  padding: 0;
  background: var(--dark);
}
.header.ghost {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
}
.header__top {
  padding: 20px 0;
  line-height: 1;
}
.header__bottom {
  border-top: 1px solid var(--neutral-75);
}
.header.light {
  background: transparent;
  .header__top {
    padding: 40px 0;
  }
}
