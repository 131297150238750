.wrapper-node {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  word-break: break-word;
}
.wrapper-node.disabled {
  color: var(--gray-6);
}
.wrapper-node.unavailable {
  color: var(--gray-6);
}
.wrapper-node.unknown {
  color: var(--main-gray-6);
}
.wrapper-node.success {
  color: var(--states-success);
}
.wrapper-node.info {
  color: var(--states-info-table);
}
